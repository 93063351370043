.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

/*.App-header {*/
/*    !*background-color: #282c34;*!*/
/*    min-height: 100vh;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: calc(10px + 2vmin);*/
/*    color: white;*/
/*}*/

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.centered-content {
    display: grid;
    justify-content: center;
}

.centered-content > div {
    display: flex;
    justify-content: center;
}

.imageLogoStyle {
    background-image: url("./images/slice-egg-64.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 48px;
    height: 48px;
    margin-top: 10px;
}

.fontLogoStyle {
    font-size: 18px;
    font-weight: bold;
}

.img-task-egg-256 {
    background-image: url("./images/task-egg-256.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 256px;
    height: 256px;
}

.ul-li-none {
    list-style-type: none;
    text-align: left;
}

.custom-menu {
    padding-left: 100px;
}

.custom-menu .ant-menu-horizontal > .ant-menu-item,
.custom-menu .ant-menu-horizontal > .ant-menu-submenu {
    float: right;
}

.custom-menu .ant-menu-horizontal > .ant-menu-item:not(:last-child),
.custom-menu .ant-menu-horizontal > .ant-menu-submenu:not(:last-child) {
    /*margin-right: 20px;*/
}

.ellipsis {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.backgroundColor34_34_34 {

    background: #232526; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #414345, #232526); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

/*background: #0F2027; !* fallback for old browsers *!*/
/*background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027); !* Chrome 10-25, Safari 5.1-6 *!*/
/*background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/

/*background-image: linear-gradient(-180deg, #504D48 5%, #33342F 96%);*/

/*background:*/
/*linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%),*/
/*linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%);*/
/*background-blend-mode: multiply;*/


.layoutStyle {
    width: 100vw;
    height: 100vh;

}

.headerStyle {
    text-align: center;
    color: #FFD700;
    width: 100vw;
    line-height: 62px;
    padding-left: 18px;
    padding-right: 18px;
    border-bottom: 1px solid hsla(45, 80%, 80%, .30);
}

.headerStyleMenu {
    text-align: center;

    /*background-color: #001529;*/
}

.contentStyle {
    text-align: center;
    color: #ffffff;
    justify-content: center;

}

.custom-menu .ant-menu-item-selected {
    color: #FFD700;
}

.border-bottom-1 {
    border-bottom: 1px solid hsla(45, 80%, 80%, .30);
}

.home-overview-div {
    /*border: 1px solid hsla(45, 80%, 80%, .30);*/
    height: 100vh;
    width: 90vw;
    /*display: flex;*/
}

.home-overview-div > h3 {
    font-size: 18px;
    text-align: left;
    margin: 5vh;
    /*width: 80vw;*/
}

.home-concept-div {
    border-bottom: 1px solid hsla(45, 80%, 80%, .30);
    height: 50vh;
    width: 90vw;
    /*display: grid;*/
}


.home-concept-div > h3 {
    font-size: 18px;
    text-align: left;
    margin: 5vh;
    /*width: 80vw;*/


}

.glass-container {
    /*width: 700px;*/
    /*height: 375px;*/
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /*border-radius: 10px;*/
    backdrop-filter: blur(2px);
    background-color: rgba(40, 40, 40, 0.3);
    /*box-shadow: rgba(0, 0, 0, 1) 2px 8px 8px;*/
    /*border: 2px rgba(255, 255, 255, 0.4) solid;*/
    /*border-bottom: 2px rgba(40, 40, 40, 0.35) solid;*/
    /*border-right: 2px rgba(40, 40, 40, 0.35) solid;*/
}

.home-background-image {
    background-image: url("./images/colorful_and_inside_the_eggegg_1.jpg");
}

.mint-background-image {
    background-image: url("./images/colorful_and_theres_egg_1009.jpg");
}

.swap-background-image {
    background-image: url("./images/colorful_and_theres_egg_1007.jpg");
}

.pool-background-image {
    background-image: url("./images/colorful_and_theres_egg_1005.jpg");
}

.game-background-image {
    background-image: url("./images/colorful_and_theres_egg_1003.jpg");
}

.background-style-css {
    margin: 0;
    padding: 0;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    font-family: Arial, sans-serif;
}

.div-glass-container {
    color: white;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    backdrop-filter: blur(3px);
    background-color: rgba(40, 40, 40, 0.2);
    box-shadow: rgba(0, 0, 0, 1) 2px 8px 8px;
    border: 1px rgba(255, 255, 255, 0.4) solid;
    border-bottom: 2px rgba(40, 40, 40, 0.35) solid;
    border-right: 2px rgba(40, 40, 40, 0.35) solid;
}

.mint-pool-swap-left-div {
    padding: 1vh;
    width: 60vw;
    height: 60vh;
    display: grid;
    place-items: center;
    margin: 1vw;
}

.mint-pool-swap-right-div {
    padding: 1vh;
    width: 30vw;
    height: 60vh;
    display: grid;
    place-items: center;
    margin: 1vw;
}

.mint-right-div-input-button {
    padding: 1vh;
    width: 25vw;
    height: 18vh;
    display: grid;
    place-items: center;
    margin: 1vw;
}

.swap-right-div-input-button {
    padding: 1vh;
    width: 25vw;
    height: 18vh;
    display: grid;
    place-items: center;
    margin: 1vw;
}

.pool-right-div-input-button {
    padding: 1vh;
    width: 25vw;
    height: 18vh;
    display: grid;
    place-items: center;
    margin: 1vw;
}

.div-table-data {
    padding: 1vh;
    width: 94vw;
    height: 50vh;
}

.swap-kline-div {
    height: 46vh;
    width: 60vw;
}
